import TranslationIcon from '~/assets/icons/translation.svg'
import ProofreadingIcon from '~/assets/icons/document-check.svg'
import TypesettingIcon from '~/assets/icons/edit-alt.svg'
import { Job } from '~/types/comic/chapter'
import { translations } from '~/translations'

const getJobIcon = (job: Job) => {
  let icon = TranslationIcon
  if(job === 'translation') icon = TranslationIcon
  if(job === 'proofreading') icon = ProofreadingIcon
  if(job === 'typesetting') icon = TypesettingIcon

  return icon
}

const getJobLabel = (job: Job) => {
  let label = ''
  if(job === 'translation') label = translations().editor.steps.translation
  if(job === 'proofreading' || job === 'typeProofreading') label = translations().editor.steps.proofreading
  if(job === 'typesetting') label = translations().editor.steps.typesetting

  return label
}

const getJobResumeLabel = (job: Job) => {
  let label = ''
  if(job === 'translation') label = translations().editor.resumeStep.translation
  if(job === 'proofreading' || job === 'typeProofreading') label = translations().editor.resumeStep.proofreading
  if(job === 'typesetting') label = translations().editor.resumeStep.typesetting
  if(job === 'validation') label = translations().editor.resumeStep.validation
  if(job === 'completed') label = translations().editor.resumeStep.completed

  return label
}

export {
  getJobIcon,
  getJobLabel,
  getJobResumeLabel
}