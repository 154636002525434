import { Component, createResource } from 'solid-js'
import styles from '~/components/series-entry/series-entry.module.scss'
import Chapters from '~/components/chapters/chapters'
import { SeriesEntryProps } from '~/components/series-entry/series-entry.interfaces'
import { getSeries } from '~/services/database/series'
import RowButton from '~/components/row-button/row-button'
import EditIcon from '~/assets/icons/edit.svg'
import SeriesCollaborators from '~/components/series-collaborators/series-collaborators'
import { getCollaborators } from '~/services/database/collaborators'
import { user } from '~/services/database/users'
import { RoleType } from '~/translations/account-roles'
import { translations } from '~/translations'

const SeriesEntry: Component<SeriesEntryProps> = ({ id }) => {
  const [series] = createResource(id, getSeries)
  const editLink = `/series/${id}/edit`
  const [collaborators] = createResource(getCollaborators(id))
  const userEntry = () => collaborators.latest?.find(entry => entry.userId === user()?.id)
  const canEditSeries = () => userEntry()?.permission === 'admin'
  const userPermission = () => userEntry()?.permission
  
  return (
    <div class={styles.seriesEntry}>
      <aside class={styles.aside}>
        <div class={styles.about}>
          <div class={styles.seriesContainer}>
            <div class={styles.title}>{series()?.title}</div>
            {series()?.author && (
              <div class={styles.author}>
                <span>{translations().series.byAuthor}</span> {series()?.author}
              </div>
            )}
          </div>
          {canEditSeries() ? (
            <RowButton
              className={styles.edit}
              icon={EditIcon}
              label={translations().general.actions.edit}
              href={editLink}
            />
          ) : (
            <div/>
          )}
          <div class={styles.editorContainer}>
            <div class={styles.label}>{translations().series.client}</div>
            <div class={styles.editor}>{series()?.client}</div>
          </div>
        </div>
        <SeriesCollaborators
          seriesId={id}
        />
      </aside>
      <Chapters
        seriesId={id}
        userRole={userPermission() as RoleType}
      />
    </div>
  )
}

export default SeriesEntry