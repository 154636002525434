const permissions = [
  {
    label: 'Traduction',
    value: 'translation'
  },
  {
    label: 'Correction',
    value: 'proofreading'
  },
  {
    label: 'Lettrage',
    value: 'typesetting'
  },
  {
    label: 'Création',
    value: 'admin'
  }
]

const getRoleName = (key: string) => {
  return permissions.find(entry => entry.value === key)?.label
}

export {
  permissions,
  getRoleName
}