import { Component, createSignal } from 'solid-js'
import Button from '~/components/button/button'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import { SeriesCollaboratorDeleteProps } from '~/components/series-collaborator-delete/series-collaborator-delete.interfaces'
import styles from '~/components/series-collaborator-delete/series-collaborator-delete.module.scss'
import { deleteCollaborator } from '~/services/database/collaborators'
import { setLoadingState } from '~/services/loading/loading'
import { translations } from '~/translations'

const SeriesCollaboratorDelete: Component<SeriesCollaboratorDeleteProps> = ({ collaborator, onSuccess }) => {
  const [showModal, setModal] = createSignal(false)

  const onConfirmDelete = () => {
    setModal(true)
  }

  const onDelete = async () => {
    setLoadingState({
      loading: true
    })
    await deleteCollaborator(collaborator.permissionId)
    setLoadingState({
      loading: false
    })
    setModal(false)
    onSuccess()
  }

  return (
    <div class={styles.seriesCollaboratorDelete}>
      <ConfirmationModal
        type='danger'
        title={translations().series.team.deleteModal.title(collaborator.name ?? collaborator.email)}
        open={showModal()}
        setOpen={setModal}
        onConfirm={onDelete}
      />
      <Button onClick={onConfirmDelete}>
        {translations().general.actions.delete}
      </Button>
    </div>
  )
}

export default SeriesCollaboratorDelete
