import { Component, Show, createResource, createSignal } from 'solid-js'
import { useNavigate } from '@solidjs/router'
import { ChapterRowProps } from '~/components/chapter-row/chapter-row.interfaces'
import styles from '~/components/chapter-row/chapter-row.module.scss'
import ConfirmationModal from '~/components/confirmation-modal/confirmation-modal'
import RowButton from '~/components/row-button/row-button'
import { deleteChapter } from '~/services/database/chapters'
import { getFileURL } from '~/services/database/file-storage'
import { reportError } from '~/services/errors'
import DeleteIcon from '~/assets/icons/delete.svg'
import EditIcon from '~/assets/icons/edit.svg'
import ChevronIcon from '~/assets/icons/chevron-down.svg'
import ClockIcon from '~/assets/icons/clock.svg'
import ArrowIcon from '~/assets/icons/arrow-right.svg'
import DownloadIcon from '~/assets/icons/download.svg'
import { getFullDate } from '~/helpers/dates'
import { getJobResumeLabel } from '~/helpers/jobs'
import { getTranslationsExport } from '~/services/export/translations'
import ExportModal from '~/components/export-modal/export-modal'
import { getChapterEditorLink } from '~/services/link'
import ChapterProgression from '~/components/chapter-progression/chapter-progression'
import { Job } from '~/types/comic/chapter'
import { setLoadingState } from '~/services/loading/loading'
import { hasReached, requiredRole } from '~/services/database/permissions'
import { translations } from '~/translations'

const ChapterRow: Component<ChapterRowProps> = ({ row, onMutate, toggled, onToggle, userRole }) => {
  const navigate = useNavigate()
  const [showConfirmationModal, setConfirmationModal] = createSignal(false)
  const [showExportModal, setExportModal] = createSignal(false)
  const updatedAtDate = new Date(row.updated_at)
  const updatedAt = getFullDate(updatedAtDate)
  const progressResumeLabel = getJobResumeLabel(row.progress as Job)

  const canEditChapter = () => userRole === 'admin'
  const canWorkOnChapter = () => userRole === requiredRole(row.progress as Job) || userRole === 'admin'
  const canExportTranslation = () => 
    (userRole === 'translation' || userRole === 'proofreading')
    || hasReached('typesetting', row.progress)
    || userRole === 'admin'

  const canExportTypesetting = () =>
    (userRole === 'typesetting' && row.progress === 'typesetting')
    || hasReached('validation', row.progress)
    || userRole === 'admin'
  
  // Resources links
  const link = getChapterEditorLink(row)
  const editLink = `/series/chapter/${row.id}/edit`
  const [assetsFileLink] = createResource(async () => {
    let link = ''
    if(row.assets_file){
      link = await getFileURL({
        path: row.assets_file,
        bucket: 'assets'
      })
    }
    return link
  })

  // Action Handlers
  const onConfirmDelete = (event: MouseEvent) => {
    event.stopPropagation()
    setConfirmationModal(true)
  }

  const onDelete = () => {
    deleteChapter({ id: row.id }).catch((error) => {
      reportError(error)
    }).finally(() => {
      onMutate()
    })
  }

  const onEdit = (event: MouseEvent) => {
    event.stopPropagation()
    navigate(editLink)
  }

  const onOpen = (event: MouseEvent) => {
    event.stopPropagation()
    navigate(link)
  }

  const onExportTranslations = async (event: MouseEvent) => {
    event.stopPropagation()
    setLoadingState({
      loading: true,
      message: translations().loading.exportingTranslations
    })
    try{
      await getTranslationsExport(row.id)
    }catch(error){
      console.error(error)
    }
    setLoadingState({
      loading: false
    })
  }

  const onExportTypesetting = async () => {
    setExportModal(true)
  }

  const onDownloadAssets = (event: MouseEvent) => {
    event.stopPropagation()
    window.open(assetsFileLink())
  }

  const cssClasses = () => ({
    [styles.chapterRow]: true,
    [styles.toggled]: toggled
  })

  return (
    <div classList={cssClasses()} onClick={onToggle}>
      <ExportModal
        open={showExportModal()}
        setOpen={setExportModal}
        chapterId={row.id}
      />

      <header class={styles.head}>
        <div class={styles.aboutChapter}>
          <div class={styles.chapterIndex}>
            <span>{translations().chapters.row.chapter}</span>
            <span class={styles.index}>{row.index}</span>
          </div>
          {row.title && (
            <div class={styles.chapterTitle}>
              {row.title}
            </div>
          )}
        </div>

        <div class={styles.progression}>
          <ChapterProgression entry={row} />
        </div>

        <aside class={styles.aside}>
          <div class={styles.lastUpdate} title={translations().chapters.lastUpdate}>
            <ClockIcon class={styles.icon} /> {updatedAt}
          </div>

          {canEditChapter() && (
            <div class={styles.actions}>
              <RowButton
                className={styles.rowButton}
                icon={DeleteIcon}
                label={translations().general.actions.delete}
                onClick={onConfirmDelete}
              />

              <ConfirmationModal
                type='danger'
                onConfirm={onDelete}
                title={translations().chapters.deleteConfirmation.title}
                description={translations().chapters.deleteConfirmation.description}
                open={showConfirmationModal()}
                setOpen={setConfirmationModal}
              />

              <RowButton
                className={styles.rowButton}
                icon={EditIcon}
                label={translations().general.actions.edit}
                onClick={onEdit}
              />
            </div>
          )}

          <div class={styles.openState}>
            <ChevronIcon class={styles.chevronIcon} />
          </div>
        </aside>
      </header>
      
      <Show when={toggled}>
        <div class={styles.toggledBlock}>
          <div class={styles.assets}>
            {canExportTranslation() && (
              <button
                class={styles.assetButton}
                onClick={onExportTranslations}
              >
                <DownloadIcon class={styles.icon} />
                <span class={styles.label}>
                  {translations().chapters.row.exportTranslation}
                </span>
              </button>
            )}
            
            {canExportTypesetting() && (
              <button
                class={styles.assetButton}
                onClick={onExportTypesetting}
              >
                <DownloadIcon class={styles.icon} />
                <span class={styles.label}>
                  {translations().chapters.row.exportTypesetting}
                </span>
              </button>
            )}

            <Show when={assetsFileLink()}>
              <button
                class={styles.assetButton}
                onClick={onDownloadAssets}
              >
                <DownloadIcon class={styles.icon} />
                <span class={styles.label}>
                  {translations().chapters.row.downloadAssets}
                </span>
              </button>
            </Show>
          </div>

          {canWorkOnChapter() && (
            <div class={styles.mainAction}>
              <button
                class={styles.button}
                onClick={onOpen}
              >
                <ArrowIcon class={styles.icon} />
                <span>{progressResumeLabel}</span>
              </button>
            </div>
          )}
        </div>
      </Show>
    </div>
  )
}

export default ChapterRow