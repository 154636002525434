import { SubmitHandler, createForm, valiForm } from '@modular-forms/solid'
import { Component } from 'solid-js'
import Button from '~/components/button/button'
import Select from '~/components/fields/select/select'
import { EditCollaboratorFormType, EditCollaboratorSchema, SeriesCollaboratorEditFormProps } from '~/components/series-collaborator-edit-form/series-collaborator-edit-form.interfaces'
import styles from '~/components/forms/styles/simple-form.module.scss'
import { updateCollaborator } from '~/services/database/collaborators'
import { roles } from '~/translations/account-roles'
import { translations } from '~/translations'
import { setLoadingState } from '~/services/loading/loading'

const SeriesCollaboratorEditForm: Component<SeriesCollaboratorEditFormProps> = ({ collaborator, onSuccess }) => {
  const [form, { Form, Field }] = createForm<EditCollaboratorFormType>({
    initialValues: {
      permission: collaborator.permission
    },
    validate: valiForm(EditCollaboratorSchema)
  })

  const onSubmit: SubmitHandler<EditCollaboratorFormType> = async (values) => {
    setLoadingState({
      loading: true
    })
    await updateCollaborator({
      ...values,
      permissionId: collaborator.permissionId
    })
    onSuccess()
    setLoadingState({
      loading: false
    })
  }

  return (
    <Form onSubmit={onSubmit} class={styles.form}>
      <Field name='permission'>
        {(field, props) => (
          <Select
            label={translations().series.team.updateModal.role}
            options={roles}
            {...field}
            {...props}
          />
        )}
      </Field>

      <Button
        type='submit'
        style='lagoon'
        isLoading={form.submitting}
      >
        {translations().general.actions.edit}
      </Button>
    </Form>
  )
}

export default SeriesCollaboratorEditForm
