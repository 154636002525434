import { Component, For, createResource, createSignal } from 'solid-js'
import { SeriesCollaboratorsProps } from '~/components/series-collaborators/series-collaborators.interfaces'
import styles from '~/components/series-collaborators/series-collaborators.module.scss'
import { getCollaborators } from '~/services/database/collaborators'
import SmallButton from '~/components/small-button/small-button'
import InviteCollaboratorModal from '~/components/invite-collaborator-modal/invite-collaborator-modal'
import SeriesCollaboratorEntry from '~/components/series-collaborator-entry/series-collaborator-entry'
import { user } from '~/services/database/users'
import { translations } from '~/translations'

const InviteCollaborator: Component<SeriesCollaboratorsProps> = ({ seriesId }) => {
  const [collaborators, { refetch }] = createResource(getCollaborators(seriesId))
  const [showModal, setShowModal] = createSignal(false)
  const userEntry = () => collaborators.latest?.find(entry => entry.userId === user()?.id)
  const canInviteCollaborators = () => userEntry()?.permission === 'admin'

  const onInvite = () => {
    setShowModal(true)
  }

  const onUpdate = () => {
    refetch()
  }

  return (
    <>
      <InviteCollaboratorModal
        open={showModal()}
        seriesId={seriesId}
        collaborators={collaborators.latest ?? []}
        setOpen={setShowModal}
        onSuccess={onUpdate}
      />
      <div class={styles.inviteCollaborator}>
        <header class={styles.header}>
          <div class={styles.title}>
            {translations().series.team.title}
          </div>
          {canInviteCollaborators() && (
            <SmallButton onClick={onInvite} transparent>
              {translations().general.actions.invite}
            </SmallButton>
          )}
        </header>
        <div class={styles.list}>
          <For each={collaborators.latest}>
            {entry => (
              <SeriesCollaboratorEntry
                entry={entry}
                onUpdate={onUpdate}
                canUpdate={canInviteCollaborators()}
              />
            )}
          </For>
        </div>
      </div>
    </>
  )
}

export default InviteCollaborator
