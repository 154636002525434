import { Component, createResource } from 'solid-js'
import Button from '~/components/button/button'
import styles from '~/components/forms/styles/complex-form.module.scss'
import { SubmitHandler, createForm, setResponse, valiForm } from '@modular-forms/solid'
import { InviteCollaboratorFormProps, InviteCollaboratorFormType, InviteCollaboratorSchema } from '~/components/forms/invite-collaborator/invite-collaborator.interface'
import Select from '~/components/fields/select/select'
import { inviteCollaborator } from '~/services/database/collaborators'
import { permissions } from '~/services/translations/permissions'
import { translations } from '~/translations'
import UserPicker from '~/components/user-picker/user-picker'
import { PickableUser } from '~/components/user-picker/user-picker.interfaces'
import { getContacts } from '~/services/database/contacts'
import FormError from '~/components/form-error/form-error'
import { APIError } from '~/types/api/errors'
import { user } from '~/services/database/users'

const InviteCollaboratorForm: Component<InviteCollaboratorFormProps> = ({ seriesId, collaborators, onSuccess }) => {
  const [form, { Form, Field }] = createForm<InviteCollaboratorFormType>({
    initialValues: {
      permission: 'translation'
    },
    validate: valiForm(InviteCollaboratorSchema)
  })

  const [contacts] = createResource(async () => {
    const contacts = await getContacts()
    const pickableUsers: PickableUser[] = contacts.map(entry => ({
      id: entry.contact_id,
      name: entry.meta?.name,
      email: entry.meta?.email,
      role: entry.meta?.preferred_role
    }))

    // Add self
    const userData = user()
    if (userData && userData.id) {
      pickableUsers.push({
        id: userData.id,
        name: userData.name,
        email: userData.email,
        role: userData.preferred_role
      })
    }

    return pickableUsers
  }, {
    initialValue: []
  })

  const isAlreadyCollaborator = (email: string) => {
    return collaborators.some(entry => entry.email === email)
  }

  const onSubmit: SubmitHandler<InviteCollaboratorFormType> = async (values) => {
    try{
      await inviteCollaborator({
        ...values,
        permission: values.permission,
        seriesId
      })
      onSuccess()
    }catch(e){
      const error = e as APIError['error']
      setResponse(form, {
        status: 'error',
        message: `${translations().errors.fatalError.title}: ${error.code}`
      })
    }
  }

  const findLabel = `${translations().general.fields.findInContacts} (${translations().account.profile.lastName}/${translations().account.profile.email})`

  return (
    <Form onSubmit={onSubmit} class={styles.modalForm}>
      <div class={styles.fieldColumnGroup}>
        <div class={styles.fieldColumn}>
          <div class={styles.fields}>
            <Field name='email'>
              {(field, props) => (
                <UserPicker
                  placeholder={findLabel}
                  users={contacts.latest}
                  form={form}
                  {...field}
                  {...props}
                />
              )}
            </Field>

            <Field name='permission'>
              {(field, props) => (
                <Select
                  label={translations().series.team.invite.role}
                  options={permissions}
                  {...field}
                  {...props}
                />
              )}
            </Field>

            <FormError response={form.response} />

            <Button
              type='submit'
              style='lagoon'
              isLoading={form.submitting}
            >
              {translations().general.actions.invite}
            </Button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default InviteCollaboratorForm