import { InferOutput, object, pipe, string, email } from 'valibot'
import { CollaboratorEntry } from '~/types/database/collaborators'
import { Database } from '~/types/supabase'

export interface InviteCollaboratorFormProps{
  seriesId: Database['public']['Tables']['series']['Row']['id']
  collaborators: CollaboratorEntry[]
  onSuccess: () => void
}

export const InviteCollaboratorSchema = object({
  email: pipe(string(), email()),
  permission: string()
})

export type InviteCollaboratorFormType = InferOutput<typeof InviteCollaboratorSchema>