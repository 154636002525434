import { InferOutput, object, string } from 'valibot'
import { CollaboratorEntry } from '~/types/database/collaborators'

export interface SeriesCollaboratorEditFormProps {
  collaborator: CollaboratorEntry
  onSuccess: () => void
}

export const EditCollaboratorSchema = object({
  permission: string()
})

export type EditCollaboratorFormType = InferOutput<typeof EditCollaboratorSchema>