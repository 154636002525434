import { useNavigate } from '@solidjs/router'
import { Component, For, Show, createEffect, createResource, createSignal } from 'solid-js'
import Button from '~/components/button/button'
import ChapterRow from '~/components/chapter-row/chapter-row'
import { ChaptersProps } from '~/components/chapters/chapters.interfaces'
import styles from '~/components/chapters/chapters.module.scss'
import EmptyState from '~/components/empty-state/empty-state'
import { getChapters } from '~/services/database/chapters'
import AddIcon from '~/assets/icons/circle-add.svg'
import DocumentEmptyIcon from '~/assets/icons/document-empty.svg'
import { translations } from '~/translations'

const Chapters: Component<ChaptersProps> = ({ seriesId, userRole }) => {
  const navigate = useNavigate()
  const canCreateChapter = () => userRole === 'admin'
  const [chapters, { refetch }] = createResource(seriesId, getChapters)

  const chaptersExist = () => {
    const chaptersCount = chapters()?.length ?? 0
    return !chapters.loading && chaptersCount > 0
  }

  const onCreateChapter = () => {
    navigate(`/series/${seriesId}/chapter/create`)
  }

  const [toggledEntries, setToggledEntries] = createSignal<boolean[]>([])

  createEffect(() => {
    const toggleStates = chapters()?.map((entry, index) => index === 0) ?? []
    setToggledEntries(toggleStates)
  })

  const toggle = (index: number) => () => {
    setToggledEntries(entries => {
      return entries.map((entry, entryIndex) => entryIndex === index)
    })
  }
  
  return (
    <div class={styles.chapterList}>
      <header class={styles.header}>
        <div class={styles.title}>
          {translations().chapters.title}
        </div>
        {canCreateChapter() && (
          <Button
            icon={AddIcon}
            onClick={onCreateChapter}
            style='borderless'
          >
            {translations().chapters.create}
          </Button>
        )}
      </header>
      
      <div class={styles.list}>
        <Show
          when={chaptersExist()}
          fallback={(
            <EmptyState
              icon={DocumentEmptyIcon}
              label={translations().chapters.emptyState.title}
              description={translations().chapters.emptyState.description}
            />
          )}
        >
          <For each={chapters()}>
            {(chapter, index) => (
              <ChapterRow
                row={chapter}
                onMutate={refetch}
                onToggle={toggle(index())}
                toggled={toggledEntries()[index()]}
                userRole={userRole}
              />
            )}
          </For>
        </Show>
      </div>
    </div>
  )
}

export default Chapters