import { useParams } from '@solidjs/router'
import SeriesEntry from '~/components/series-entry/series-entry'
import Header from '~/components/header/header'
import { SeriesParams } from '~/types/routes/series/entry'

export default function Series() {
  const { id } = useParams<SeriesParams>()
  return (
    <>
      <Header />
      <SeriesEntry id={id} />
    </>
  )
}