import { Component } from 'solid-js'
import InviteCollaboratorForm from '~/components/forms/invite-collaborator/invite-collaborator'
import { InviteCollaboratorModalProps } from '~/components/invite-collaborator-modal/invite-collaborator-modal.interfaces'
import Modal from '~/components/modal/modal'
import { translations } from '~/translations'

const InviteCollaboratorModal: Component<InviteCollaboratorModalProps> = ({ open, setOpen, seriesId, onSuccess, collaborators }) => {
  const onClose = () => {
    onSuccess()
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      title={translations().dashboard.contacts.invite.title}
    >
      <InviteCollaboratorForm
        seriesId={seriesId}
        onSuccess={onClose}
        collaborators={collaborators}
      />
    </Modal>
  )
}

export default InviteCollaboratorModal
