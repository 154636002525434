import { Component, createSignal, For, Show } from 'solid-js'
import { PickableUser, UserPickerProps } from '~/components/user-picker/user-picker.interfaces'
import styles from '~/components/user-picker/user-picker.module.scss'
import { fieldId } from '~/services/form/helpers'
import CheckIcon from '~/assets/icons/check.svg'
import { Role } from '~/services/database/permissions'
import Levenshtein from 'levenshtein'
import { setValue } from '@modular-forms/solid'
import { translations } from '~/translations'

const UserPicker: Component<UserPickerProps> = ({ name, label, placeholder, users, value, form, error }) => {
  const id = fieldId()
  const getRoleLabel = (role: string) => new Role(role).label

  const [currentUser, setUser] = createSignal<PickableUser>()
  const [inviteEmail, setInviteEmail] = createSignal<string>()

  let inputRef!: HTMLInputElement
  const [filteredEntries, setFilteredEntries] = createSignal(users)
  const [hasFocus, setHasFocus] = createSignal(false)
  const completionClasses = () => ({
    [styles.completions]: true,
    [styles.show]: hasFocus(),
  })

  const onInput = () => {
    const searchTerm = inputRef.value
    setFilteredEntries(() => {
      const result = searchTerm.length === 0 ? users : users.filter(entry => {
        const lv = new Levenshtein(entry.name ?? '', searchTerm)
        const directMatch = entry.name?.toLowerCase().includes(searchTerm.toLowerCase())
        return directMatch || lv.distance < 10
      })
      return result
    })
  }

  const onFocus = () => {
    setHasFocus(true)
    onInput()
    setTimeout(() => {
      inputRef.focus()
    }, 100)
  }

  const onBlur = () => {
    setTimeout(() => {
      setHasFocus(false)
    }, 300)
  }

  const onSelect = (user: PickableUser) => () => {
    setValue(form, name, user.email)
    setUser(user)
    setInviteEmail(undefined)
    setHasFocus(false)
  }

  const onEmailInvite = () => {
    setValue(form, name, inputRef.value)
    setInviteEmail(inputRef.value)
    setHasFocus(false)
  }

  const onKeyDown = (event: KeyboardEvent) => {
    const isSubmitting = event.code === 'Enter'
    if(isSubmitting){
      event.preventDefault()
      const entries = filteredEntries()
      const hasSuggestions = entries.length > 0
      const hasText = inputRef.value.length > 0
      if (hasSuggestions) {
        onSelect(entries[0])()
      } else if (hasText) {
        onEmailInvite()
      }
    }
  }

  return (
    <div class={styles.userPicker}>
      {label && (
        <label
          for={id}
          class={styles.label}
        >
          {label}
        </label>
      )}

      <div class={styles.autocompleteField}>
        <Show
          when={(currentUser() || inviteEmail()) && !hasFocus()}
          fallback={(
            <input
              ref={inputRef}
              class={styles.input}
              placeholder={placeholder}
              autocomplete='none'
              onInput={onInput}
              onFocus={onFocus}
              onBlur={onBlur}
              onKeyDown={onKeyDown}
            />
          )}
        >
          <button
            class={styles.currentValue}
            type='button'
            onClick={onFocus}
          >
            {inviteEmail() && (
              <div class={styles.entry}>
                <div class={styles.about}>
                  <div class={styles.name}>
                    {inviteEmail()}
                  </div>
                </div>
              </div>
            )}

            {currentUser() && (
              <div class={styles.entry}>
                <div class={styles.about}>
                  <div class={styles.name}>
                    {currentUser()?.name}
                  </div>
                  <div class={styles.email}>
                    {currentUser()?.email}
                  </div>
                </div>

                <Show when={currentUser()?.role}>
                  {role => (
                    <div class={[styles.role, styles[role()]].join(' ')}>
                      {getRoleLabel(role())}
                    </div>
                  )}
                </Show>
              </div>
            )}
          </button>
        </Show>

        <div classList={completionClasses()}>
          <For
            each={filteredEntries()}
            fallback={(
              <button
                class={styles.entry}
                onClick={onEmailInvite}
                type='button'
              >
                <div class={styles.invite}>
                  {translations().general.actions.invite}
                </div>
              </button>
            )}
          >
            {(user, index) => (
              <button
                class={[styles.entry, index() === 0 ? styles.highlighted : null].join(' ')}
                onClick={onSelect(user)}
                type='button'
              >
                {value === user.id && (
                  <CheckIcon class={styles.check} />
                )}

                <div class={styles.about}>
                  <div class={styles.name}>
                    {user.name}
                  </div>
                  <div class={styles.email}>
                    {user.email}
                  </div>
                </div>

                {user.role && (
                  <div class={[styles.role, styles[user.role]].join(' ')}>
                    {getRoleLabel(user.role)}
                  </div>
                )}
              </button>
            )}
          </For>
        </div>
      </div>

      {error && (
        <div class={styles.error}>
          {error}
        </div>
      )}
    </div>
  )
}

export default UserPicker

